import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import MouseOverPopover from "./popover";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text sx={{
      fontSize: 4,
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hey! I'm Arvind
    </Text>
    <MouseOverPopover mdxType="MouseOverPopover" />
    <p>{`Former runner turned `}<a parentName="p" {...{
        "href": "https://www.huffpost.com/entry/transitioning-from-colleg_b_6604806"
      }}>{`narp`}</a>{`,
occasional piano student, and recent indoor gardener.
I enjoy brewing a nice cup of coffee and going on 3 hour internet binges on random topics`}</p>
    <p>{`Current interests lie in: writing, markets and tennis 🎾`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      